/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/axios@1.7.5/dist/axios.min.js
 * - /npm/splitting@1.1.0/dist/splitting.min.js
 * - /npm/three-js@79.0.0/three.min.js
 * - /npm/animejs@3.2.2/lib/anime.min.js
 * - /npm/@barba/core@2.10.3/dist/barba.umd.min.js
 * - /npm/@finsweet/attributes-cmsload@1.12.0/cmsload.js
 * - /npm/@glidejs/glide@3.6.0/dist/glide.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
